import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"
import image1 from '../../../images/documentation/delete-cycle-1.png'
import image2 from '../../../images/documentation/delete-cycle-2.png'

const DeleteTestCycle = () => {
  return (
    <LayoutComponent>
      <h3>Delete Test Cycle</h3>
      <ol>
        <li>
          Go to <strong>Test Cycles</strong>.
        </li>
        <li>
          Select the Test Cycle you want to delete.
        </li>
        <li>
          Click <strong>Delete</strong>.
          <br />
          <img width="100%" src={image1} />
        </li>
        <li>A confirmation box will appera to show what all would be removed along with the test cycle
          <br />
          <img width="100%" src={image2} />
        </li>
        <li>Your test cycle is deleted along with the all the associated data.</li>
      </ol>
    </LayoutComponent>
  )
}

export default DeleteTestCycle
